<script>
  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import CustomizeCard from '@/components/customize/CustomizeCard'
  import BoxStatus from '@/components/customize/BoxStatus'
  import { ChoiceAutoSelectModalTypes } from '@/store/customize'

  export default {
    components: {
      CustomizeCard,
      BoxStatus,
    },
    data() {
      return {
        ChoiceAutoSelectModalTypes,
      }
    },
    computed: {
      ...get('feature', ['flags']),
      ...get('account', ['shipments']),
      ...get('config', ['currentSeason', 'currentYear']),
      ...get('choicePlusCart', ['lineItems', 'cartQuantity']),
      ...get('customize', [
        'chosenVariants',
        'hasCustomizableSubscriptions',
        'hasAwaitingShipmentSubscriptions',
        'filteredSelectedPurchasedChoicePlusVariants',
        'selectedBoxAllVariants',
        'filteredSelectedPurchasedChoicePlusVariantIds',
        'savedChooseForMeVariantIds',
        'choiceChooseForMeVariant',
        'isMysteryVariant',
      ]),
      ...get('customize', {
        boxChoiceTabSelected: 'myBoxShowBoxChoicesTab',
        choicePlusTabSelected: 'myBoxShowChoicePlusTab',
      }),
      screen,
      boxStatus() {
        if (this.hasAwaitingShipmentSubscriptions) {
          return 'prepped'
        } else if (this.hasCustomizableSubscriptions) {
          return 'saved'
        }
        return 'shipped'
      },
      autoSelectedByVariantId() {
        if (this.shipments.length) {
          return this.shipments[0].lineItems.reduce((variantIds, lineItem) => {
            variantIds[lineItem.variant.id] = lineItem.isAutoSelected
            return variantIds
          }, {})
        }
        return {}
      },
      shipmentVariants() {
        const shipmentVariantIds = this.shipments.length
          ? this.shipments[0].lineItems.map(({ variant }) => variant.id)
          : []
        return this.selectedBoxAllVariants.filter(({ id }) => shipmentVariantIds?.includes(id))
      },
      variantsToDisplay() {
        if (this.boxChoiceTabSelected) {
          if (this.hasCustomizableSubscriptions) {
            return this.chosenVariants
          }
          return this.shipmentVariants
        } else {
          if (this.hasCustomizableSubscriptions) {
            const cartVariants = this.lineItems.map(({ variant }) => variant)
            return cartVariants.concat(this.filteredSelectedPurchasedChoicePlusVariants)
          }
          return this.filteredSelectedPurchasedChoicePlusVariants
        }
      },
      truncatedYear() {
        return this.currentYear?.toString()?.slice(-2)
      },
      displayChoicePlusTab() {
        return (
          this.hasCustomizableSubscriptions ??
          this.filteredSelectedPurchasedChoicePlusVariants.length
        )
      },
      displayOnlyBoxChoicesTab() {
        return (
          !this.hasCustomizableSubscriptions &&
          !this.filteredSelectedPurchasedChoicePlusVariants.length &&
          !this.screen.lg
        )
      },
      isChooseForMeEnabled() {
        return this.flags['choose-for-me'] ?? false
      },
      heroImage() {
        const currentSeason = this.currentSeason?.toLowerCase() ?? 'spring'
        return `/static/customize/mybox-hero-${currentSeason}-${
          this.screen.md ? 'desktop' : 'mobile'
        }.png`
      },
    },
    methods: {
      ...call('customize', { toggleTab: 'toggleMyBoxSelectedChoicesTab' }),
      ...call('customize', ['setChoiceAutoSelectModalVisible']),
      isAutoSelectedByVariantId(variant) {
        return variant ? this.autoSelectedByVariantId[variant.id] : false
      },
      isChooseForMeVariant(variant, choiceNumber) {
        const chooseForMeVariantSaved = this.savedChooseForMeVariantIds.includes(
          this.choiceChooseForMeVariant(choiceNumber).id
        )
        return this.isChooseForMeEnabled && !variant && chooseForMeVariantSaved
      },
      handleCustomizeCardDetails(variant, choiceNumber) {
        if (this.isChooseForMeVariant(variant, choiceNumber)) {
          this.setChoiceAutoSelectModalVisible({
            modalVisible: true,
            choiceAutoSelectModalType: ChoiceAutoSelectModalTypes.CHOOSE_FOR_ME,
          })
        } else if (this.isMysteryVariant(variant)) {
          this.setChoiceAutoSelectModalVisible({
            modalVisible: true,
            choiceAutoSelectModalType: ChoiceAutoSelectModalTypes.MYSTERY,
          })
        } else {
          this.$emit('details', variant.id)
        }
      },
    },
  }
</script>

<template>
  <div>
    <!-- Hero image -->
    <BaseImage :src="heroImage" alt="" responsive="xl" class="border-b-2 border-black" />
    <div class="w-full lg:px-10 xl:px-25 lg:py-12">
      <div class="flex flex-col-reverse lg:flex-row lg:items-center justify-between px-4 lg:px-0">
        <div class="flex flex-col">
          <!-- Desktop heading -->
          <h1 v-if="screen.lg" class="font-heading font-medium text-7xl leading-none my-0">
            {{ currentSeason }} Box ‘{{ truncatedYear }}
          </h1>
          <!-- Navigation tabs ('Your choices' and 'Your Choice+') -->
          <ul
            role="tablist"
            :class="`flex ${
              displayOnlyBoxChoicesTab ? 'justify-start' : 'justify-evenly lg:justify-start'
            } mt-4 lg:mt-6 text-sm lg:text-lg`"
          >
            <li
              :class="`${boxChoiceTabSelected || !screen.lg ? 'font-semibold' : ''} mr-6`"
              role="presentation"
            >
              <div
                :class="`pb-11px lg:pb-5 ${
                  displayOnlyBoxChoicesTab ? 'px-0' : 'px-5 xs:px-8 lg:px-0'
                } ${
                  boxChoiceTabSelected && !displayOnlyBoxChoicesTab
                    ? 'border-b-2 lg:border-b-1 border-black '
                    : ''
                }`"
              >
                <BaseLink
                  role="tab"
                  :aria-selected="boxChoiceTabSelected ? 'true' : 'false'"
                  tabindex="0"
                  @click="toggleTab({ tab: 'boxChoice' })"
                >
                  Your choices
                </BaseLink>
              </div>
            </li>
            <li
              v-if="displayChoicePlusTab"
              :class="`${cartQuantity > 0 ? '-mt-1 lg:mt-0' : ''} ${
                choicePlusTabSelected || !screen.lg ? 'font-semibold ' : ''
              }`"
              role="presentation"
            >
              <div
                :class="`pb-11px lg:pb-5 px-5 xs:px-8 lg:px-0 ${
                  choicePlusTabSelected ? 'border-b-2 lg:border-b-1 border-black' : ''
                }`"
              >
                <BaseLink
                  role="tab"
                  :aria-selected="choicePlusTabSelected ? 'true' : 'false'"
                  tabindex="0"
                  class="flex items-end lg:items-center"
                  @click="toggleTab({ tab: 'choicePlus' })"
                >
                  <span>Your Choice+</span>
                  <!-- Choice+ cart quantity badge -->
                  <div
                    v-if="cartQuantity > 0 && hasCustomizableSubscriptions"
                    class="flex justify-center items-center w-6 h-6 ml-1 px-1 rounded-full bg-summer font-semibold text-dawn-lt4 text-base"
                  >
                    <span>{{ cartQuantity }}</span>
                  </div>
                </BaseLink>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <!-- Mobile heading -->
          <h1 v-if="!screen.lg" class="font-heading font-medium text-4xl leading-none mt-5 mb-3">
            {{ currentSeason }} Box ‘{{ truncatedYear }}
          </h1>
          <!-- Box status -->
          <BoxStatus :status="boxStatus" class="mb-5 lg:mb-0" />
          <BaseDivider v-if="!screen.lg" color="black" class="-mx-4" />
        </div>
      </div>
      <div v-if="!displayOnlyBoxChoicesTab" class="-mt-1px mb-6">
        <BaseDivider class="absolute w-full" />
      </div>
      <div class="px-4 lg:px-0">
        <div
          v-if="choicePlusTabSelected && !variantsToDisplay.length"
          class="text-center mt-58px lg:mt-22 lg:mb-36"
        >
          <span class="font-heading font-medium text-lg lg:text-34px">
            Want to add more choices to your box?
          </span>
          <p class="text-xs lg:text-xl">
            You can add additional products at a big discount with Choice+.
            <br v-if="screen.lg" />
            Add more below!
          </p>
          <BaseDivider v-if="!screen.lg" class="mt-58px" />
        </div>
        <!-- Box choices / Choice+ cart variants and/or purchased Choice+ variants -->
        <ul v-else class="flex flex-col lg:grid lg:grid-cols-6 lg:gap-4">
          <li
            v-for="(variant, variantIndex) in variantsToDisplay"
            :key="variantIndex"
            class="mb-2px"
          >
            <CustomizeCard
              :variant="variant"
              :choice-step="boxChoiceTabSelected ? variantIndex + 1 : undefined"
              :layout="screen.lg ? 'vertical' : 'horizontal'"
              :cta="hasCustomizableSubscriptions && boxChoiceTabSelected ? 'Edit' : 'View'"
              :is-choice-plus-view="choicePlusTabSelected"
              :has-bottom-border="!screen.lg"
              :selected-by-alltrue="isAutoSelectedByVariantId(variant)"
              @edit="$emit('edit', variantIndex + 1)"
              @details="handleCustomizeCardDetails(variant, variantIndex + 1)"
              @remove="$emit('remove', { variantId: variant.id })"
            />
          </li>
        </ul>
      </div>
      <BaseDivider v-if="screen.lg" class="mt-4" />
      <!-- Action bar with 'Edit choices' and 'Box Summary' buttons (+ 'Checkout Choice+ Items' if there are items in Choice+ cart) -->
      <div class="flex justify-between px-4 lg:px-0 mb-6 lg:mb-0">
        <div
          :class="`flex mt-9 lg:mt-22px w-full lg:w-auto  ${
            cartQuantity > 0 && !screen.lg && choicePlusTabSelected ? 'hidden' : ''
          }`"
        >
          <BaseButton
            v-if="screen.lg && hasCustomizableSubscriptions"
            height="shorter"
            outline-only
            class="lg:mr-13px"
            @click="$emit('customize')"
          >
            <span class="font-semibold text-3xs">Edit choices</span>
          </BaseButton>
          <BaseButton
            :height="!screen.lg ? 'normal' : 'shorter'"
            outline-only
            class="w-full lg:w-auto"
            @click="$emit('summary')"
          >
            <span class="font-semibold text-sm lg:text-3xs">
              {{ screen.lg ? 'View box summary' : 'Box summary' }}
            </span>
          </BaseButton>
        </div>
        <BaseButton
          v-if="
            cartQuantity > 0 &&
            hasCustomizableSubscriptions &&
            (screen.lg || (!screen.lg && choicePlusTabSelected))
          "
          :height="!screen.lg ? 'normal' : 'shorter'"
          class="w-full lg:w-auto mt-6 lg:mt-9px"
          @click="$emit('summary')"
        >
          <span class="font-semibold text-sm lg:text-3xs">
            Checkout Choice+ Items ({{ cartQuantity }})
          </span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>
