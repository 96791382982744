<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import ShippingModal from '@/components/marketplace/ShippingModal'

  export default {
    components: {
      ShippingModal,
    },
    props: {
      status: {
        type: String,
        default: undefined,
        validator: (value) => ['saved', 'prepped', 'shipped'],
      },
    },
    data() {
      return {
        shippingModalVisible: false,
      }
    },
    computed: {
      ...get('account', ['activeSubscriptions']),
      ...get('config', ['currentSeason']),
      ...get('customize', ['findBoxBySubscription']),
      screen,
      formattedCurrentSeason() {
        return this.currentSeason?.toLowerCase() ?? 'winter' // fallback
      },
      choiceEndDate() {
        return new Date(
          this.findBoxBySubscription(this.activeSubscriptions?.[0]).choiceAnnualEndDate?.replace(
            /-/g,
            '/'
          )
        )
      },
      formattedChoiceEndDate() {
        const day = this.formatDate({ day: 'numeric' })
        const month = this.formatDate({ month: 'long' })
        return `${month} ${day}`
      },
      selectedStatus() {
        const content = {}
        switch (this.status) {
          case 'saved':
            content.heading = 'Your choices have been saved.'
            content.byline = '' // was `You have until ${this.formattedChoiceEndDate} to make changes.`
            content.link = 'View fulfillment and shipping timelines.'
            content.imageSrc = '/static/illustrations/IllustrationBoxCart.png'
            break
          case 'prepped':
            content.heading = 'We are prepping your box.'
            content.byline = 'We‘ll notify you when it‘s ready to be shipped.'
            content.link = 'View fulfillment and shipping timelines.'
            content.imageSrc = '/static/illustrations/IllustrationBoxCustomize.png'
            break
          case 'shipped':
            content.heading = 'Your box has been shipped (woohoo!).'
            content.byline = 'Keep an eye out for your goods.'
            content.link = 'View tracking info.'
            content.imageSrc = `/static/illustrations/IllustrationBoxShipped_${this.formattedCurrentSeason}.png`
            break
          default:
            break
        }
        return content
      },
    },
    methods: {
      formatDate(options) {
        return new Intl.DateTimeFormat('en-US', options).format(this.choiceEndDate)
      },
      getDateOrdinal(day) {
        if (day > 3 && day < 21) return 'th'
        switch (day % 10) {
          case 1:
            return 'st'
          case 2:
            return 'nd'
          case 3:
            return 'rd'
          default:
            return 'th'
        }
      },
      ctaLink() {
        if (this.status === 'shipped') {
          this.$navigate({ name: 'AccountProfileSubscriptions' })
        } else {
          this.shippingModalVisible = true
        }
      },
    },
  }
</script>

<template>
  <div>
    <div class="flex items-center">
      <BaseImage
        :src="selectedStatus.imageSrc"
        alt=""
        background-transparent
        responsive="xs"
        sizes="128px"
        class="max-w-50px"
      />
      <div
        :class="`text-2xs leading-snug-tight ml-4 ${
          status === 'prepped' ? 'max-w-90' : 'max-w-105'
        } `"
      >
        <span class="font-semibold">
          {{ selectedStatus.heading }}
        </span>
        <br v-if="!screen.lg && selectedStatus.byline" />
        <span>
          {{ selectedStatus.byline }}
        </span>
        <br v-if="!screen.lg" />
        <BaseLinkStyled color="black" @click="ctaLink">
          {{ selectedStatus.link }}
        </BaseLinkStyled>
      </div>
    </div>
    <ShippingModal
      :open="shippingModalVisible"
      type="choicePlus"
      @dismiss="shippingModalVisible = false"
    />
  </div>
</template>
