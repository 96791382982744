<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  export default {
    computed: {
      screen,
      ...get('account', ['activeSubscriptions']),
      ...get('customize', [
        'isCustomizable',
        'awaitingShipmentSubscriptions',
        'customizationIsComplete',
      ]),
      sortedSubscriptions() {
        // sort by subscription type, in alphabetical order of type + awaiting shipment first
        return [...this.activeSubscriptions].sort((a, b) => {
          if (this.subscriptionIsAwaitingShipment(a) || a.type < b.type) {
            return -1
          }
          if (a.type > b.type) {
            return 1
          }
          return 0
        })
      },
    },
    methods: {
      subscriptionIsQuarterly(subscription) {
        return subscription?.type === 'quarterly'
      },
      subscriptionIsAwaitingShipment(subscription) {
        return this.awaitingShipmentSubscriptions.some((sub) => sub === subscription)
      },
    },
  }
</script>

<template>
  <ul>
    <li
      v-for="(subscription, subscriptionIndex) in sortedSubscriptions"
      :key="subscriptionIndex"
      :class="`flex justify-between items-center py-4 ${
        // don't show bottom border below last sub on mobile/tablet
        !screen.lg && subscriptionIndex === sortedSubscriptions.length - 1
          ? ''
          : 'border-b border-gray-active'
      } `"
    >
      <div class="text-sm">
        <BaseAddress
          v-if="subscription.address"
          :address="subscription.address"
          include-name
          class="text-left"
        />
      </div>
      <div class="ml-2 flex-none">
        <BaseLinkStyled
          v-if="subscriptionIsAwaitingShipment(subscription)"
          icon-position="right"
          color="black"
          :icon-size="2"
          class="italic text-2xs font-medium"
          :to="{ name: 'AccountProfileSubscriptions' }"
        >
          View Box Shipment
          <template v-slot:icon>
            <IconChevronRightThick />
          </template>
        </BaseLinkStyled>
        <button
          v-else-if="isCustomizable(subscription)"
          class="bg-primary text-dawn p-2 text-2xs"
          @click="$emit('customize', subscription.id)"
        >
          <span v-if="customizationIsComplete(subscription)"> View Customization </span>
          <span v-else> Customize </span>
        </button>
        <button
          v-else-if="subscriptionIsQuarterly(subscription)"
          class="bg-summer text-dawn p-2 text-2xs"
          @click="$emit('upgrade', subscription.id)"
        >
          Upgrade to customize
        </button>
      </div>
    </li>
  </ul>
</template>
