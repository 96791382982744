<script>
  import { get, call } from 'vuex-pathify'
  import Player from '@vimeo/player'
  import screen from '@/helpers/screen'
  import CustomizeCard from '@/components/customize/CustomizeCard'
  import LogoChoicePlus from '@/images/logos/LogoChoicePlus.svg'

  export default {
    components: {
      CustomizeCard,
      LogoChoicePlus,
    },
    props: {
      /** Whether the user has an intro box. */
      hasIntroBox: {
        type: Boolean,
        required: true,
      },
      /** Whether the cta should allow users to add choice+ to their cart. */
      displayChoicePlus: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        videoPlayer: null,
        showChoicePlusAlertModal: false,
      }
    },
    computed: {
      ...get('account', [
        'hasOnlyExpiredSubscriptions',
        'isQuarterlySubscriber',
        'activeSubscriptions',
      ]),
      ...get('config', ['currentBox', 'currentSeason', 'currentBoxIsCoreBox']),
      ...get('customize', [
        'customizableBoxProducts',
        'videoTimestamp',
        'getProductIndex',
        'getChoiceStepColor',
        'hasCustomizableSubscriptions',
        'isVariantPurchased',
        'isMysteryVariant',
      ]),
      screen,
      customizableBoxVariants() {
        let variants = this.customizableBoxProducts?.flatMap((product) => product.variants)
        variants = variants?.filter((variant) => !this.isMysteryVariant(variant))
        return variants ?? []
      },
      videoSrc() {
        return ''
        // return `https://player.vimeo.com/video/686834410${
        //   this.videoTimestamp ? `#t=${this.videoTimestamp}` : ''
        // }?h=529b554573&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
      },
      header() {
        return this.displayChoicePlus ? 'Buy More With Choice+' : 'Look Inside the Box'
      },
      showPastBoxesBanner() {
        if (this.hasOnlyComingSoonSubscriptions && this.isQuarterlySubscriber) {
          return !this.screen.lg
        }
        return true
      },
    },
    mounted() {
      this.videoPlayer = this.$refs.revealVideo ? new Player(this.$refs.revealVideo) : null
    },
    methods: {
      ...call('customize', ['setVideoTimestamp']),
      async goToPdp(variantId) {
        this.$emit('details', variantId)
        if (!this.hasIntroBox) {
          const seconds = await this.videoPlayer.getCurrentTime()
          this.setVideoTimestamp({ time: seconds })
        }
      },
    },
  }
</script>

<template>
  <div class="w-full lg:px-10 xl:px-25 lg:mb-6">
    <div
      :class="`md:flex md:justify-between md:items-center ${
        displayChoicePlus ? 'mt-8' : 'mt-10 mb-3'
      } md:my-12 mx-5 md:mx-4 lg:mx-0`"
    >
      <div>
        <h2 class="font-heading leading-heading text-center md:text-left text-4xl md:text-6xl">
          {{ header }}
        </h2>
        <p
          v-if="!displayChoicePlus"
          class="text-sm lg:text-lg text-center md:text-left mt-2 md:mt-4"
        >
          Take a peek at all the products available this season. It’s like <br v-if="screen.md" />
          a map for your most delightful decision making.
        </p>
      </div>
      <div v-if="screen.md && currentBoxIsCoreBox" class="text-right">
        <BaseLinkStyled
          v-if="isQuarterlySubscriber || hasOnlyExpiredSubscriptions"
          :color="hasOnlyExpiredSubscriptions ? 'black' : 'summer'"
          font-weight="semibold"
          underlined
          @click="hasOnlyExpiredSubscriptions ? $emit('reactivate') : $emit('upgrade')"
        >
          <span v-if="isQuarterlySubscriber"> Upgrade to customize</span>
          <span v-else-if="hasOnlyExpiredSubscriptions"> Reactivate Membership </span>
        </BaseLinkStyled>
        <BaseLink v-else-if="displayChoicePlus" @click="showChoicePlusAlertModal = true">
          <BaseIcon class="inline-block align-middle text-primary w-15px mb-2px">
            <IconHelpFilled />
          </BaseIcon>
          <span class="font-semibold text-2xs leading-snug-tight">HOW CHOICE+ WORKS</span>
        </BaseLink>
      </div>
    </div>
    <!-- Mobile box reveal video -->
    <div :class="`${displayChoicePlus && !screen.md ? 'flex flex-col-reverse' : ''}`">
      <template v-if="!screen.md && videoSrc">
        <div v-if="!hasIntroBox" class="px-4">
          <div v-if="displayChoicePlus" class="mt-8">
            <BaseDivider color="black" :weight="2" class="-mx-4" />
            <h2 class="mt-8 font-heading leading-heading text-center text-4xl">
              Look Inside the box
            </h2>
            <p class="text-sm text-center mt-2 mb-6">
              Take a peek at all the products available this season. It’s like a map for your most
              delightful decision making.
            </p>
          </div>
          <div style="padding-bottom: 56.25%">
            <iframe
              ref="revealVideo"
              :src="videoSrc"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="Winter 2021 Box Reveal"
              class="absolute top-0 left-0 w-full h-full"
            />
          </div>
          <div class="flex items-center mt-4 mb-7">
            <div class="w-12">
              <BaseImage src="/static/customize/Hannah_Avatar.jpg" alt="" rounded />
            </div>
            <div class="ml-4">
              <span class="font-semibold text-xs">
                Watch the {{ currentSeason }} Box reveal with Hannah!
              </span>
              <br />
              <p class="text-2xs leading-tight">
                Get the inside scoop on the products from our head curator.
              </p>
            </div>
          </div>
        </div>
      </template>

      <!-- Desktop list of variants (show if displayChoicePlus is true - if false, only on screen.md) -->
      <ul
        v-if="displayChoicePlus ? true : screen.md"
        class="mx-4 lg:mx-0 grid justify-center gap-x-5 gap-y-8 grid-cols-2 md:grid-cols-4 lg:grid-cols-5"
      >
        <!-- Desktop box reveal video -->
        <div v-if="!hasIntroBox && videoSrc" class="col-span-2 row-span-1">
          <div v-if="screen.md">
            <div style="padding-bottom: 56.25%">
              <iframe
                ref="revealVideo"
                :src="videoSrc"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="Winter 2021 Box Reveal"
                class="absolute top-0 left-0 w-full h-full"
              />
            </div>
            <div class="flex items-center mt-4 mb-7">
              <div class="w-9">
                <BaseImage src="/static/customize/Hannah_Avatar.jpg" alt="" rounded />
              </div>
              <div class="ml-4">
                <span class="font-semibold text-xs">
                  Watch the {{ currentSeason }} Box reveal with Hannah!
                </span>
                <br />
                <p class="text-2xs leading-tight">
                  Get the inside scoop on the products from our head curator.
                </p>
              </div>
            </div>
          </div>
        </div>
        <li v-for="variant in customizableBoxVariants" :key="variant.id">
          <CustomizeCard
            :variant="variant"
            layout="vertical"
            :has-choice-plus-button="displayChoicePlus"
            :is-choice-plus-view="displayChoicePlus"
            :choice-step="!displayChoicePlus ? getProductIndex(variant.productId) + 1 : undefined"
            @addToCart="$emit('addToCart', { variantId: variant.id })"
            @remove="$emit('remove', { variantId: variant.id })"
            @details="goToPdp(variant.id)"
          />
        </li>
      </ul>
      <!-- Mobile list of variants -->
      <ul v-else>
        <li v-for="(choice, choiceIndex) in customizableBoxProducts" :key="choiceIndex">
          <!-- for purgecss: bg-winter bg-fall bg-spring bg-primary bg-sunshine bg-summer bg-dawn -->
          <div
            :class="`pl-4 py-1 bg-${getChoiceStepColor(choiceIndex)} w-full text-xs font-medium`"
          >
            Choice Product {{ choiceIndex + 1 }}
          </div>
          <div
            v-for="(variant, variantIndex) in choice.variants"
            :key="variantIndex"
            :class="`mx-4 ${
              variantIndex < choice.variants.length - 1 ? 'border-b border-dawn-dk2' : ''
            }`"
          >
            <CustomizeCard
              :variant="variant"
              layout="horizontal"
              class="py-14px"
              @details="goToPdp(variant.id)"
            />
          </div>
        </li>
      </ul>
    </div>

    <Portal to="modal">
      <BaseModal
        :open="showChoicePlusAlertModal"
        size="xs"
        bg-color="dawn"
        dismissible
        hide-icon
        @dismiss="showChoicePlusAlertModal = false"
      >
        <div>
          <BaseIcon class="w-1/2 mx-auto mb-6">
            <LogoChoicePlus class="w-full pb-1" />
          </BaseIcon>

          <p class="text-xs text-center mb-8">
            Can’t decide? With Choice+ you can purchase additional products from each category at a
            discounted price.
          </p>

          <BaseButton @click="showChoicePlusAlertModal = false">Got it</BaseButton>
        </div>
      </BaseModal>
    </Portal>
  </div>
</template>

<style lang="postcss" scoped>
  .bg-primary {
    @apply text-dawn;
  }
</style>
