<script>
  import screen from '@/helpers/screen'

  export default {
    computed: {
      screen,
      imageSrc() {
        return this.screen.lg
          ? '/static/boxArchive/Explore-Past-Boxes-Desktop-Winter.png'
          : '/static/boxArchive/Explore-Past-Boxes-Mobile-Winter.png'
      },
      imageSize() {
        return this.screen.lg ? 'md' : 'xs'
      },
      iconSize() {
        return this.screen.lg ? 3 : 2
      },
    },
  }
</script>

<template>
  <div
    class="explore-past-boxes-container bg-primary text-dawn md:mt-16 lg:mx-10 pt-10 pb-38px px-14px lg:py-50px lg:px-58px text-center lg:text-left lg:grid lg:grid-cols-2 lg:justify-items-center lg:items-center lg:gap-x-27"
  >
    <BaseImage
      class="explore-past-box-img mx-auto lg:mx-0"
      :src="imageSrc"
      :responsive="imageSize"
      alt=""
      background-transparent
    />
    <div class="flex flex-col justify-center">
      <h2 class="explore-heading font-heading text-2xl lg:text-64px mt-6 lg:mt-0">
        Explore <br v-if="screen.lg" />Past Boxes
      </h2>
      <p
        class="mt-1 lg:mt-4 max-w-76 lg:max-w-125 mx-auto lg:mx-0 text-sm lg:text-lg leading-close"
      >
        Get a feel for the brands and products (everything from hammocks to hand creams) that we
        curate each season for our community.
      </p>
      <BaseLinkStyled
        class="mt-5 lg:mt-8"
        color="dawn"
        icon-position="right"
        type="link"
        :icon-size="iconSize"
        :underlined="false"
        :to="{ name: 'BoxArchive' }"
      >
        <template v-slot:icon>
          <IconChevronRightThick class="mt-1px lg:-mt-1px" />
        </template>
        <template v-slot>
          <span class="text-xs lg:text-lg font-medium leading-close"> See past boxes </span>
        </template>
      </BaseLinkStyled>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */
  .explore-past-boxes-container {
    @screen lg {
      border-radius: 43px;
    }
  }

  .explore-past-box-img {
    max-width: 243px;

    @screen lg {
      max-width: 554px;
    }

    >>> img {
      border-radius: 24px;

      @screen lg {
        border-radius: 28px;
      }
    }
  }

  .explore-heading {
    line-height: 1.2;

    @screen lg {
      line-height: 0.94;
    }
  }
  /* purgecss end ignore */
</style>
