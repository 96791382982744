<script>
  /**
   * A list of features used to upsell subscriptions. Uses the 'summer' checkmark to order the list.
   */
  import screen from '@/helpers/screen'

  export default {
    props: {
      /** The list of features to display. */
      featureList: {
        type: Array,
        required: true,
      },
    },
    computed: {
      screen,
    },
  }
</script>

<template>
  <ul>
    <li
      v-for="(feature, featureIndex) in featureList"
      :key="featureIndex"
      class="font-body mb-2 md:mr-1 flex"
    >
      <BaseIcon type="icon" label="checkmark" :size="screen.xl ? 5 : 4" class="text-summer mt-2px">
        <IconCheckmarkWide />
      </BaseIcon>
      <span class="ml-2 text-sm lg:text-lg text-left">
        {{ feature }}
        <template v-if="feature.includes('extra') || feature.includes('additional products')">
          <button class="font-semibold underline" @click="$emit('choicePlus')"> Choice+ </button>
        </template>
      </span>
    </li>
  </ul>
</template>
