<script>
  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import debounce from '@/helpers/debounce'
  import UpgradeConfirmation from '@/components/account/UpgradeConfirmation'
  import UpgradeSuccess from '@/components/account/UpgradeSuccess'
  import CustomizeInsideTheBox from '@/components/customize/CustomizeInsideTheBox'
  import CustomizeMultipleSubscriptions from '@/components/customize/CustomizeMultipleSubscriptions'
  import CustomizeUpgradeFeatures from '@/components/customize/CustomizeUpgradeFeatures'
  import SelectedChoices from '@/components/customize/SelectedChoices'
  import MobileNavLinks from '@/components/app/MobileNavLinks'
  import WaitlistModal from '@/components/modals/WaitlistModal'
  import LogoChoicePlus from '@/images/logos/LogoChoicePlus.svg'
  import BoxArchiveExplorePastBoxesBanner from '@/components/boxArchive/BoxArchiveExplorePastBoxesBanner'
  import HelpMessaging from '@/components/app/HelpMessaging'

  export default {
    components: {
      UpgradeConfirmation,
      UpgradeSuccess,
      CustomizeInsideTheBox,
      CustomizeMultipleSubscriptions,
      CustomizeUpgradeFeatures,
      LogoChoicePlus,
      MobileNavLinks,
      WaitlistModal,
      SelectedChoices,
      BoxArchiveExplorePastBoxesBanner,
      HelpMessaging,
    },
    data() {
      return {
        submitting: false,
        confirmed: false,
        upgradeSuccessfulVisible: false,
        error: null,
        upgradeSuccessfulTimeout: null,
        response: null,
        confirmationModalOpen: false,
        selectedSubscriptionId: null,
        featureList: [
          'Customize your entire box',
          'Buy additional products with',
          'Save $20 each year',
        ],
        showChoiceAlertModal: false,
        showCustomizationTooltipModal: false,
        upgradeButtonsAreNotInViewport: true,
        topButtonRef: null,
        bottomButtonRef: null,
        waitlistModalVisible: false,
      }
    },
    computed: {
      ...get('feature', ['flags']),
      ...get('general', ['navigating']),
      ...get('account', [
        'activeSubscriptions',
        'isQuarterlySubscriber',
        'subscriptionCanAddBonus',
        'hasOnlyExpiredSubscriptions',
        'isSubscriptionWaitlist',
        'isSubscriptionOffWaitlist',
      ]),
      ...get('config', [
        'currentSeason',
        'currentYear',
        'isBoxCoreBox',
        'getFormattedCurrentBoxStartDate',
      ]),
      ...get('customize', [
        'customizableSubscriptions',
        'customizationIsComplete',
        'findBoxBySubscription',
        'isAwaitingShipment',
        'customizationIsLive',
        'hasCustomizableSubscriptions',
        'currentSubscriptions',
        'chosenVariants',
        'selectedSubscription',
        'latestBoxCustomizationDates',
        'hasOnlyComingSoonSubscriptions',
        'customizationStartDate',
        'savedChooseForMeVariantIds',
        'choiceChooseForMeVariantBaseId',
        'hasOpenBoxCarts',
      ]),
      ...get('prismic', ['customizeLandingPageData']),
      screen,
      customizationEndDate() {
        return this.latestBoxCustomizationDates?.endDay
      },
      heroImage() {
        if (!this.screen.md) {
          return this.customizeLandingPageData?.mobile_image?.url
        }
        return this.customizeLandingPageData?.image?.url
      },
      currentYearShort() {
        return this.currentYear?.toString().slice(2, 4)
      },
      hasMultipleActiveSubscriptions() {
        return this.activeSubscriptions.length > 1
      },
      hasOnlyIntroBoxes() {
        return (
          this.activeSubscriptions?.length &&
          this.activeSubscriptions.every(
            (subscription) => !this.isBoxCoreBox(this.findBoxBySubscription(subscription))
          )
        )
      },
      hasOnlyAwaitingShipmentSubscriptions() {
        return (
          this.activeSubscriptions.every((subscription) => this.isAwaitingShipment(subscription)) &&
          !this.hasCustomizableSubscriptions
        )
      },
      // !TODO: replace once design is ready
      hideLookInsideBoxSection() {
        /* Hide section if user has multiple subs which includes an intro AND core box.
        This is a temporary solution until we have a design for this edge case. */
        const hasCoreBox = this.activeSubscriptions.some((subscription) =>
          this.isBoxCoreBox(this.findBoxBySubscription(subscription))
        )
        const hasIntroBox = this.activeSubscriptions.some(
          (subscription) => !this.isBoxCoreBox(this.findBoxBySubscription(subscription))
        )
        /* Also hide if box is coming soon (not customizable yet) */
        // every sub's box is open and not customizable
        return (
          (hasIntroBox && hasCoreBox) ||
          this.hasOnlyComingSoonSubscriptions ||
          this.isSubscriptionWaitlist()
        )
      },
      buttonProps() {
        const props = {}
        if (this.hasCustomizableSubscriptions) {
          props.color = 'primary'
          props.clickAction = this.navigateToCustomize
        } else if (this.isQuarterlySubscriber) {
          props.color = 'summer'
          props.clickAction = this.upgradeQuarterly
        } else if (this.hasOnlyExpiredSubscriptions) {
          props.color = 'black'
          props.clickAction = () => {
            this.$navigate({ name: 'SubscribePlan' })
          }
        }
        return props
      },
      isChooseForMeEnabled() {
        return this.flags['choose-for-me'] ?? false
      },
      showSelectedChoices() {
        // undefined variants show 'Select a Product' option
        // when user skips/doesn't finish selecting choices
        const containsSelectedVariant = this.chosenVariants?.some(
          (variant) => variant !== undefined
        )
        // annual users always see this except when customization is live and they haven't started customizing yet
        const showForAnnual = this.hasCustomizableSubscriptions ? containsSelectedVariant : true
        // quarterly users only see this when their box has been shipped
        const showForQuarterly = !this.hasOpenBoxCarts && this.shipments?.[0]?.status === 'shipped'

        return (
          this.activeSubscriptions.length === 1 &&
          !this.hasOnlyComingSoonSubscriptions &&
          ((!this.isQuarterlySubscriber && showForAnnual) ||
            (this.isQuarterlySubscriber && showForQuarterly)) &&
          Boolean(this.flags['my-box-selected-choices-view'])
        )
      },
      showMobileStickyButton() {
        return (
          this.isQuarterlySubscriber ||
          this.hasOnlyExpiredSubscriptions ||
          (this.hasCustomizableSubscriptions && !this.showSelectedChoices)
        )
      },
    },
    watch: {
      confirmed() {
        clearTimeout(this.upgradeSuccessfulTimeout)
        if (this.confirmed) {
          this.confirmationModalOpen = false
          this.upgradeSuccessfulVisible = true
          this.upgradeSuccessfulTimeout = setTimeout(() => {
            this.navigateToCustomize(this.response.id)
          }, 2000)
        }
      },
    },
    created() {
      if (this.showSelectedChoices) {
        // get purchased choice plus products
        const subscriptionReference = this.selectedSubscription?.reference
        this.fetchPurchasedChoicePlusVariants({ subscriptionReference })
      }
      this.loadCustomizeLandingPageData()
    },
    async mounted() {
      // Refresh the `upgradeButtonsAreNotInViewport` property
      await this.ensureUpgradeButtonRefsExist()
      const topUpgradeElement = this.topButtonRef?.$el ?? this.topButtonRef
      const bottomUpgradeElement = this.bottomButtonRef?.$el ?? this.bottomButtonRef
      const refreshUpgradeButtonsAreNotInViewport = () => {
        this.upgradeButtonsAreNotInViewport =
          bottomUpgradeElement.getBoundingClientRect().bottom >= window.innerHeight &&
          topUpgradeElement.getBoundingClientRect().top <= 0
      }
      refreshUpgradeButtonsAreNotInViewport()
      // Also refresh it as the user scrolls, with an IntersectionObserver
      const observer = new window.IntersectionObserver(
        () => refreshUpgradeButtonsAreNotInViewport(),
        {
          threshold: 1.0,
        }
      )
      observer.observe(topUpgradeElement)
      observer.observe(bottomUpgradeElement)
      this.$once('hook:beforeDestroy', () => {
        observer.disconnect()
      })
      // Also refresh it when the window is resized
      const handleResize = debounce(refreshUpgradeButtonsAreNotInViewport, 200)
      window.addEventListener('resize', handleResize)
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', handleResize)
      })
    },
    methods: {
      ...call('subscribe', ['upgradeSubscription']),
      ...call('customize', ['load', 'fetchPurchasedChoicePlusVariants']),
      ...call('choicePlusCart', ['addToCart', 'removeCartItem']),
      ...call('prismic', ['loadCustomizeLandingPageData']),
      async ensureUpgradeButtonRefsExist() {
        await this.$waitForValue(() => this.topButtonRef)
        await this.$waitForValue(() => this.bottomButtonRef)
      },
      navigateToDetails(variantId) {
        this.$navigate({
          name: 'CustomizeDetails',
          params: {
            boxId: this.currentSubscriptions?.[0]?.boxId,
            variantId: variantId,
          },
        })
      },
      navigateToCustomize(subscriptionId) {
        const id =
          this.customizableSubscriptions.length === 1
            ? this.customizableSubscriptions[0].id
            : subscriptionId

        this.$navigate({
          name: 'CustomizeSubscription',
          params: { subscriptionId: id },
        })
      },
      navigateToCustomizationStep(choiceNumber) {
        this.$navigate({
          name: 'CustomizeChoice',
          params: {
            subscriptionId: this.activeSubscriptions?.[0]?.id,
            choice: choiceNumber,
          },
        })
      },
      navigateToSummary() {
        this.$navigate({
          name: 'CustomizeSummary',
          params: {
            subscriptionId: this.activeSubscriptions?.[0]?.id,
          },
        })
      },

      async upgradeQuarterlyImmediately() {
        this.submitting = true
        try {
          this.response = await this.upgradeSubscription({
            subscriptionId: this.selectedSubscriptionId,
          })
          await this.load()
          this.confirmed = true
        } catch (error) {
          if (error) {
            this.error = error.data.message
          }
        } finally {
          this.submitting = false
        }
      },
      upgradeQuarterly(subscriptionId = this.activeSubscriptions[0].id) {
        this.selectedSubscriptionId = subscriptionId
        if (this.selectedSubscriptionId) {
          this.confirmationModalOpen = true
        }
      },
      showSubscriptionDetailsModal() {
        if (this.isSubscriptionWaitlist()) {
          this.waitlistModalVisible = true
        } else {
          this.showCustomizationTooltipModal = true
        }
      },
      htmlSerializer(type, element, text, children) {
        if (text && text.includes('currentSeason')) {
          text = text.replace('{{ currentSeason }}', this.currentSeason)
          return text
        }
      },
    },
  }
</script>

<template>
  <div>
    <Portal to="productModal">
      <RouterView />
    </Portal>

    <MobileNavLinks />
    <SelectedChoices
      v-if="showSelectedChoices"
      class="border-t-2 md:border-t-0 border-b-2 border-black"
      @customize="navigateToCustomize"
      @edit="navigateToCustomizationStep($event)"
      @details="navigateToDetails($event)"
      @summary="navigateToSummary"
      @remove="removeCartItem($event)"
    />
    <div
      v-else
      class="flex flex-col items-center lg:flex-row border-t-2 border-b-2 border-black -mt-2px"
    >
      <div :class="`w-full ${hasOnlyIntroBoxes && !screen.lg ? 'pt-4 absolute z-20' : ''}`">
        <div
          :class="`mt-8 md:my-12 lg:my-0 max-w-76 2xs:max-w-81 xs:max-w-125  mx-auto text-center lg:text-left ${
            isQuarterlySubscriber && customizationIsLive
              ? 'md:max-w-110 lg:max-w-115 xl:max-w-135'
              : 'md:max-w-150 lg:max-w-95'
          }`"
        >
          <!------------------------ Quarterly kicker above header ------------------------>
          <div
            v-if="customizationIsLive && isQuarterlySubscriber"
            class="font-semibold mb-4 md:mb-6"
          >
            <PrismicRichText
              v-if="customizeLandingPageData"
              :field="customizeLandingPageData.title1"
              :html-serializer="htmlSerializer"
            />
          </div>
          <!------------------------ Header start ------------------------>
          <h1 class="font-heading text-46px lg:text-7xl leading-heading">
            <span v-if="isSubscriptionOffWaitlist">Your box is ready!</span>
            <span v-else-if="isSubscriptionWaitlist()">Waitlist Reserve</span>
            <!-- Customization live -->
            <template v-else-if="customizationIsLive">
              <!-- Intro box -->
              <span v-if="hasOnlyIntroBoxes">{{ currentSeason }} Welcome Box</span>
              <!-- Core box (at least 1) -->
              <span v-else-if="isQuarterlySubscriber">
                Want to pick every product in your Box?
              </span>
              <span v-else>
                <PrismicRichText
                  v-if="customizeLandingPageData"
                  :field="customizeLandingPageData.title1"
                  :html-serializer="htmlSerializer"
                />
              </span>
            </template>

            <!-- Coming soon -->
            <template v-else-if="hasOnlyComingSoonSubscriptions">
              <span>
                {{ currentSeason }} Box <br />
                Coming Soon!
              </span>
            </template>

            <!-- Every other case (closed/awaiting shipment, expired subs) -->
            <span v-else> {{ currentSeason }} Box '{{ currentYearShort }} </span>
          </h1>
          <!------------------------ Header end ------------------------>

          <!------------------------ Description copy start ------------------------>
          <p
            :class="`mt-4 ${
              isQuarterlySubscriber && customizationIsLive ? '' : 'mb-8'
            } lg:mt-5 lg:mb-10 text-md leading-close lg:leading-normal`"
          >
            <span v-if="isSubscriptionWaitlist()">
              Your box is reserved! Once our next box becomes available your purchase will be
              processed automatically (with a $5 discount), and you‘ll be first in line to receive
              it!
            </span>
            <!-- Customization live -->
            <template v-else-if="customizationIsLive">
              <!-- Quarterly -->
              <div v-if="isQuarterlySubscriber" class="flex flex-col items-center lg:items-start">
                <p class="md:text-lg mb-3 lg:mb-6">
                  <span v-if="isSubscriptionOffWaitlist">
                    Want to customize everything inside?
                  </span>
                  <span v-else>Upgrade to an Annual Membership to...</span>
                </p>
                <CustomizeUpgradeFeatures
                  :feature-list="featureList"
                  @choicePlus="showChoiceAlertModal = true"
                />
              </div>
              <!-- Annual -->
              <PrismicRichText
                v-else-if="customizeLandingPageData"
                :field="customizeLandingPageData.description_copy"
                :html-serializer="htmlSerializer"
              />
            </template>

            <!-- Coming soon -->
            <template v-else-if="hasOnlyComingSoonSubscriptions">
              <!-- Quarterly -->
              <span v-if="isQuarterlySubscriber">
                Want to pick every product in your {{ currentSeason }} Box? Upgrade to an Annual
                Membership
                <span v-if="customizationStartDate && customizationEndDate" class="font-semibold">
                  - customization runs {{ customizationStartDate }}-{{ customizationEndDate }}.
                </span>
              </span>
              <!-- Annual -->
              <span v-else>
                Our {{ currentSeason }} Box is almost here, so prepare to pick your products!
                <span class="font-semibold">
                  Customization runs {{ customizationStartDate }}-{{ customizationEndDate }}.
                </span>
              </span>
            </template>

            <!-- Closed/awaiting shipment -->
            <span v-else-if="hasOnlyAwaitingShipmentSubscriptions">
              We're preparing your box! Be on the <br v-if="!screen.xs" />
              lookout for an email notification as <br v-if="!screen.xs" />
              soon as your order has shipped.
            </span>

            <!-- Expired subs -->
            <span v-else-if="hasOnlyExpiredSubscriptions">
              We miss you! Restart your journey and <br v-if="!screen.xs" />
              get this season's <br v-if="screen.xs && !screen.lg" />
              curation, plus access <br v-if="!screen.xs" />
              to our socially-conscious Essentials <br v-if="!screen.lg" />
              Shop, and many more!
            </span>
          </p>
          <!------------------------ Description copy end ------------------------>

          <!------------------------ Hero CTA (button or link) start ------------------------>
          <!-- Desktop multiple subscriptions -->
          <CustomizeMultipleSubscriptions
            v-if="hasMultipleActiveSubscriptions && !hasOnlyComingSoonSubscriptions && screen.lg"
            class="max-w-130 mx-auto mb-10"
            @customize="navigateToCustomize($event)"
            @upgrade="upgradeQuarterly($event)"
          />

          <!-- Single sub -->
          <template v-else-if="!hasMultipleActiveSubscriptions">
            <!-- Desktop annual / quarterly / expired (no CTA for coming soon) -->
            <BaseButton
              v-if="isSubscriptionWaitlist()"
              disabled
              class="mb-8 lg:mb-12 max-w-96 mx-auto lg:mx-0"
            >
              Waitlist joined
            </BaseButton>
            <BaseButton
              v-else-if="
                screen.lg &&
                (hasCustomizableSubscriptions ||
                  isQuarterlySubscriber ||
                  hasOnlyExpiredSubscriptions)
              "
              v-ref="topButtonRef"
              :icon-size="3"
              icon-position="right"
              :color="buttonProps.color"
              data-cy="customize-button"
              class="button-cta"
              @click="buttonProps.clickAction()"
            >
              <!-- Annual -->
              <template v-if="hasCustomizableSubscriptions">
                <span v-if="customizationIsComplete(customizableSubscriptions[0])">
                  View Box Customization
                </span>
                <span v-else> Start customizing </span>
              </template>

              <!-- Quarterly -->
              <template v-else-if="isQuarterlySubscriber">
                <span> Upgrade now </span>
              </template>

              <!-- Expired -->
              <span v-else-if="hasOnlyExpiredSubscriptions"> Reactivate Membership </span>

              <template
                v-if="hasCustomizableSubscriptions || hasOnlyExpiredSubscriptions"
                v-slot:icon
              >
                <IconChevronRightThick />
              </template>
            </BaseButton>

            <!-- Mobile expired subs / Desktop + mobile closed/awaiting shipment subs -->
            <BaseLinkStyled
              v-else-if="
                (!screen.lg && hasOnlyExpiredSubscriptions) || hasOnlyAwaitingShipmentSubscriptions
              "
              icon-position="right"
              color="black"
              :icon-size="2"
              class="uppercase text-2xs md:text-md font-medium mb-4"
              :to="
                hasOnlyExpiredSubscriptions
                  ? { name: 'SubscribePlan' }
                  : { name: 'AccountProfileSubscriptions' }
              "
            >
              <span v-if="hasOnlyExpiredSubscriptions"> Reactivate Membership </span>
              <span v-else-if="hasOnlyAwaitingShipmentSubscriptions"> View box shipments </span>
              <template v-slot:icon>
                <IconChevronRightThick />
              </template>
            </BaseLinkStyled>
          </template>
          <!------------------------ CTA (button or link) end ------------------------>

          <!-- How customization works tooltip link  -->
          <BaseLink
            v-if="isSubscriptionWaitlist() || isQuarterlySubscriber || hasCustomizableSubscriptions"
            :class="`flex justify-center lg:justify-start items-center ${
              isQuarterlySubscriber && customizationIsLive ? '-ml-2 md:ml-0 mt-1 mb-5' : 'mb-10'
            } lg:mb-0`"
            @click="showSubscriptionDetailsModal"
          >
            <BaseIcon :size="screen.xs ? 5 : 4" class="inline-block align-middle mr-2 text-primary">
              <IconHelpFilled />
            </BaseIcon>
            <span class="mt-1px font-medium text-primary text-sm lg:text-md leading-none">
              <span v-if="isSubscriptionWaitlist()"> Waitlist Details </span>
              <span v-else-if="isQuarterlySubscriber" class="underline">
                Learn more about Annual Membership
              </span>
              <span v-else> How does customization work? </span>
            </span>
          </BaseLink>
        </div>
      </div>

      <!-- Hero image -->
      <BaseImage :src="heroImage" alt="" responsive="lg" class="lg:max-w-50vw" />
    </div>

    <!-- Mobile multiple subscriptions -->
    <div
      v-if="hasMultipleActiveSubscriptions && !hasOnlyComingSoonSubscriptions && !screen.lg"
      :class="`px-4 flex flex-col md:grid ${
        hasOnlyComingSoonSubscriptions ? 'md:grid-cols-1' : 'md:grid-cols-2'
      } border-b-2 border-black`"
    >
      <div v-if="!hasOnlyComingSoonSubscriptions" class="text-center md:text-left text-xs my-4">
        <h2 class="hidden md:block font-heading text-4xl mt-10"> Customize your Box </h2>
        <span>Select a Membership to customize.</span>
      </div>
      <CustomizeMultipleSubscriptions
        class="md:my-2"
        @customize="navigateToCustomize($event)"
        @upgrade="upgradeQuarterly($event)"
      />
    </div>

    <!-- Inside the box -->
    <CustomizeInsideTheBox
      v-if="!hideLookInsideBoxSection"
      :has-intro-box="hasOnlyIntroBoxes"
      :display-choice-plus="hasCustomizableSubscriptions && activeSubscriptions.length === 1"
      @addToCart="addToCart($event)"
      @reactivate="$navigate({ name: 'SubscribePlan' })"
      @upgrade="upgradeQuarterly(activeSubscriptions[0].id)"
      @details="navigateToDetails($event)"
      @remove="removeCartItem($event)"
    />

    <template v-if="!hasMultipleActiveSubscriptions && !isSubscriptionWaitlist()">
      <!-- Annual upsell for quarterly + coming soon -->
      <div
        v-if="isQuarterlySubscriber"
        :class="`${
          customizationIsLive ? '' : 'mt-10'
        } flex flex-col items-center font-heading mb-6 md:mt-20 lg:mb-15 px-4 md:px-0`"
      >
        <BaseDivider
          v-if="customizationIsLive"
          :color="screen.md ? 'black' : 'dawn-dk2'"
          :weight="screen.md ? 2 : 1"
          class="w-full mb-10"
        />
        <span class="text-2xl leading-snug"> Perks of </span>
        <span class="font-semibold text-34px lg:text-7xl mb-3 leading-tighter text-center">
          Annual Membership
        </span>
        <CustomizeUpgradeFeatures
          :feature-list="featureList"
          class="ml-9 lg:ml-0"
          @choicePlus="showChoiceAlertModal = true"
        />
      </div>

      <!-- Desktop upgrade button below 'Inside the Box' section -->
      <div
        v-if="isQuarterlySubscriber"
        class="mb-10 lg:mb-20 lg:mb-4 max-w-73 lg:max-w-170 mx-auto lg:flex items-center justify-center"
      >
        <BaseButton
          v-ref="bottomButtonRef"
          color="summer"
          class="my-6 lg:my-0 max-w-59 lg:max-w-158 mx-auto lg:mx-0"
          @click="upgradeQuarterly(activeSubscriptions[0].id)"
        >
          Upgrade to customize
        </BaseButton>
        <p class="font-medium text-center lg:text-left lg:ml-8 text-xs max-w-105">
          Upgrade to Annual Membership to pick your products. <br v-if="screen.lg" />
          You’ll also get access to Choice+, which lets you buy additional items from every category
          (deciding is hard, we get it).
        </p>
      </div>
    </template>

    <BoxArchiveExplorePastBoxesBanner v-if="!hasCustomizableSubscriptions" />

    <footer class="lg:mt-14 -mb-14 lg:mb-0">
      <BaseDivider color="dawn-dk2" />
      <ul class="flex justify-evenly my-6 lg:mt-7 text-sm">
        <li>
          <BaseLink :to="{ name: 'AccountProfileSubscriptions' }">Box order history</BaseLink>
        </li>
        <span v-if="!screen.lg">&#8729;</span>
        <li>
          <BaseLink :to="{ name: 'AccountProfileSubscriptions' }">My subscriptions</BaseLink>
        </li>
        <template v-if="screen.lg">
          <li>
            <BaseLink href="https://alltrue.com/contact-us" target="_blank">
              Need help? Contact us
            </BaseLink>
          </li>
          <li>
            <BaseLink href="https://faq.alltrue.com/hc/en-us" target="_blank">FAQs</BaseLink>
          </li>
        </template>
      </ul>
      <div
        v-if="!screen.lg"
        :class="`px-1 py-4 bg-black text-dawn text-center text-xs z-20 bottom-0 ${
          showMobileStickyButton && !hasMultipleActiveSubscriptions ? 'mb-15' : ''
        }`"
      >
        <HelpMessaging />
      </div>
    </footer>

    <Portal to="modal">
      <!-- Annual upsell modals -->
      <UpgradeConfirmation
        v-if="confirmationModalOpen"
        :submitting="submitting"
        :error="error"
        :subscription-id="selectedSubscriptionId"
        @upgrade="upgradeQuarterlyImmediately"
        @dismiss="confirmationModalOpen = false"
      />
      <UpgradeSuccess v-else-if="upgradeSuccessfulVisible" />

      <!-- Tooltip modals -->
      <BaseModal
        v-else-if="showCustomizationTooltipModal || showChoiceAlertModal"
        :open="showCustomizationTooltipModal || showChoiceAlertModal"
        size="sm"
        bg-color="dawn-lt3"
        dismissible
        hide-nav-on-mobile
        @dismiss="
          showCustomizationTooltipModal
            ? (showCustomizationTooltipModal = false)
            : (showChoiceAlertModal = false)
        "
      >
        <!-- Customization tooltip -->
        <template v-if="showCustomizationTooltipModal">
          <p class="text-xs text-center my-8">
            <span v-if="isQuarterlySubscriber">
              Our Annual Membership provides access to box customization and yearly savings for an
              upfront commitment to four seasonal boxes. In addition to saving $20 per year and
              choosing products, Annual Members also unlock access to Choice+, which allows for the
              addition of multiple products from each choice category at significant discounts (up
              to 70%+ off).
            </span>
            <span v-else>
              Choose your favorite product in each customization category. Want more products? You
              will have the option to buy more at a discounted price.
            </span>
          </p>
        </template>

        <!-- Choice+ tooltip -->
        <template v-else>
          <BaseIcon class="w-1/2 mx-auto mb-6 text-primary">
            <LogoChoicePlus class="w-full pb-1" />
          </BaseIcon>
          <p class="text-xs text-center mb-8">
            Want more than one product? With Choice+ you can purchase additional choice products
            from each category at a discounted price.
          </p>
        </template>
        <BaseButton
          @click="
            showCustomizationTooltipModal
              ? (showCustomizationTooltipModal = false)
              : (showChoiceAlertModal = false)
          "
        >
          Got it
        </BaseButton>
      </BaseModal>
    </Portal>

    <!-- Sticky CTA banners for users with 1 sub -->
    <Portal v-if="!hasMultipleActiveSubscriptions && !isSubscriptionWaitlist()" to="fixedBottom">
      <BaseTransitionFixedBottom>
        <!-- Sticky button for mobile -->
        <template v-if="!screen.lg">
          <BaseButton
            v-if="showMobileStickyButton"
            :color="buttonProps.color"
            :icon-size="3"
            icon-position="right"
            :disabled="navigating"
            data-cy="customize-button"
            @click="buttonProps.clickAction()"
          >
            <!-- Annual -->
            <template v-if="hasCustomizableSubscriptions">
              <span v-if="customizationIsComplete(customizableSubscriptions[0])">
                View Box Customization
              </span>
              <span v-else> Start customizing </span>
            </template>

            <!-- Quarterly -->
            <span v-else-if="isQuarterlySubscriber"> Upgrade now </span>

            <!-- Expired -->
            <span v-if="hasOnlyExpiredSubscriptions"> Reactivate Membership </span>

            <template v-slot:icon>
              <IconChevronRightThick />
            </template>
          </BaseButton>
        </template>
        <!-- Desktop quarterly upsell (only when top and bottom buttons are not in viewport) -->
        <div
          v-else-if="isQuarterlySubscriber && upgradeButtonsAreNotInViewport"
          class="pt-3 pb-14px flex bg-summer max-w-158 mx-auto items-center justify-center"
        >
          <BaseButton color="white" @click="upgradeQuarterly(activeSubscriptions[0].id)">
            <span class="text-summer">Upgrade to customize </span>
          </BaseButton>
          <p class="ml-8 text-white text-xs max-w-100">
            Upgrade to Annual Membership to pick your products. You’ll also get access to Choice+,
            which lets you buy additional items from every category (deciding is hard, we get it).
          </p>
        </div>
      </BaseTransitionFixedBottom>
    </Portal>
    <WaitlistModal :open="waitlistModalVisible" @dismiss="waitlistModalVisible = false" />
  </div>
</template>

<style lang="postcss" scoped>
  .button-cta {
    @apply max-w-96 mt-8 mb-10 mx-auto;
    @screen lg {
      @apply mx-0;
    }
  }

  .hero-image-height {
    min-height: 27.3125rem;

    @screen 2xs {
      min-height: 35.3125rem;
    }

    @screen sm {
      min-height: 42.25rem;
    }

    @screen lg {
      min-height: initial;
    }
  }
</style>
